.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-burger-bars {
  background: #373a47;
}
.bm-burger-bars-hover {
  background: goldenrod;
}

.bm-burger-button {
  position: absolute;
  width: 2.4em;
  height: 2em;
  left: 95%;
  top: 5%;
}
.bm-item {
  display: inline-block;
  text-decoration: none;
  color: white;
  font-size: 1.15em;
  padding-top: 1em;
}
a .bm-menu {
  color: #b8b7ad;
  font-weight: 700;
}

a .bm-menu:hover,
:focus {
  color: goldenrod;
}

.bm-item:hover {
  color: goldenrod;
}

.bm-item:focus {
  outline: none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}
